exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-article-js": () => import("./../../../src/pages/article.js" /* webpackChunkName: "component---src-pages-article-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-my-first-post-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=C:/Users/lfros/my_gatsby_site/blog/my-first-post.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-my-first-post-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-my-second-post-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=C:/Users/lfros/my_gatsby_site/blog/my-second-post.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-my-second-post-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-my-third-post-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=C:/Users/lfros/my_gatsby_site/blog/my-third-post.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-my-third-post-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recipes-js": () => import("./../../../src/pages/recipes.js" /* webpackChunkName: "component---src-pages-recipes-js" */)
}

